<template>
  <div class="card-container">

    <h5 v-if="organisation.display_name">
      {{ organisation.display_name }}
    </h5>
    <h5 v-else class="placeholder">
      {{ $t('words.new') }}
    </h5>
    
    <ImportImage
      :type="'logo'"
      :name="'organisation-thumbnail'"
      :title="thumbnail ? thumbnail.name : null"
      :file="thumbnail ? thumbnail.url : null"
      :show-file-name="false"
      :show-input="showImgInput"
      class="thumbnail-selector"
      @update="setThumbnail"
    />
    <p class="label-help">
      {{ $t('forms.datasets.thumbnail.help') }}
    </p>

    <span
      v-if="$route.name !== 'AddOrganisation'"
      style="margin-bottom: 0.5em; font-weight: 600;"
    >
      {{ organisation && organisation.usergroup && organisation.usergroup.members_count > 0 ?
          organisation.usergroup.members_count :
          $t('words.noneMasculine')
      }}
      {{ $tc('words.user', organisation && organisation.usergroup ? organisation.usergroup.members_count : 0) }}
    </span>
    <div v-if="usergroup && usergroup.parents.length > 0">
      <span style="font-weight: 600;">
        {{ $t('cards.organisation.attachedToGroup') }}
      </span>
      <ul>
        <li v-for="parent of parents" :key="parent.id">
          {{ parent.display_name }}
        </li>
      </ul>
    </div>
    <span v-else-if="$route.name !== 'AddOrganisation'">
      {{ $t('cards.organisation.attachedToNoGroup') }}
    </span>
  </div>
</template>

<script>
import ImportImage from '@/components/ImportImage.vue';

import { mapState } from 'vuex';

export default {
  name: 'OrganisationCard',
  
  components: {
    ImportImage
  },

  props: {
    organisation: {
      type: Object,
      default: () => { return {} }
    },
    usergroup: {
      type: Object,
      default: () => { return {
        parents: []
      } }
    }
  },

  data() {
    return {
      thumbnail: null,
      showImgInput: false
    }
  },

  computed: {
    ...mapState('usergroups', [
      'usergroupsList'
    ]),
    parents() {
      return this.usergroupsList.filter(el => this.usergroup.parents.includes(el.id) )
    },
    thumbnailURL() {
      if (this.organisation.thumbnail) {
        return new URL(this.organisation.thumbnail.url, process.env.VUE_APP_DOMAIN);
      } else {
        return ''
      }
    }
  },

  watch: {
    organisation: {
      deep: true,
      handler(newValue) {
        if (newValue.thumbnail) {
          this.thumbnail = newValue.thumbnail;
        }
      }
    }
  },

  created() {
    if (this.organisation) {
      this.thumbnail = this.organisation.thumbnail;
    }
  },

  methods: {
    setThumbnail(e) {
      if (e) {
        const formData = new FormData();
        formData.append('file', e);
        this.thumbnail = formData;
        this.$emit('set', this.thumbnail);
      } else {
        this.thumbnail = e;
        this.$emit('set', this.thumbnail);
      }
    }
  }
}
</script>

<style lang="less" scoped>

.placeholder {
  color: rgb(196, 196, 196);
}

ul {
  list-style: square;
}

</style>
